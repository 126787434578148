.buttonproffer {
    min-width: 300px;
    min-height: 60px;
    font-family: 'Nunito', sans-serif;
    font-size: 22px;
    text-transform: uppercase;
    letter-spacing: 1.3px;
    font-weight: 700;
    color: #313133;
    background: #08C18A;
  background: linear-gradient(90deg, #08C18A 0%, #08C18A 100%);
    border: none;
    border-radius: 1000px;
    transition: all 0.3s ease-in-out 0s;
    cursor: pointer;
    outline: none;
    position: relative;
    padding: 10px;
    margin-top: "20px";
    }
  
  .buttonproffer::before {
  content: '';
    border-radius: 1000px;
    min-width: calc(300px + 12px);
    min-height: calc(60px + 12px);
    border: 6px solid #08C18A;
    box-shadow: 0 0 60px #08C18A;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: all .3s ease-in-out 0s;
  }
  
  .buttonproffer:hover, .buttonproffer:focus {
    color: #313133;
    transform: translateY(-6px);
  }
  
  .buttonproffer:hover::before, .buttonproffer:focus::before {
    opacity: 1;
  }
  
  .buttonproffer::after {
    content: '';
    width: 30px; height: 30px;
    border-radius: 100%;
    border: 6px solid #08C18A;
    position: absolute;
    z-index: -1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: ring 1.5s infinite;
  }
  
  .buttonproffer:hover::after, .buttonproffer:focus::after {
    animation: none;
    display: none;
  }
  
  @keyframes ring {
    0% {
      width: 30px;
      height: 30px;
      opacity: 1;
    }
    100% {
      width: 300px;
      height: 300px;
      opacity: 0;
    }
  }
  .my-swal-container {
    z-index: 9999999;
    /* Additional styles for the container */
  }